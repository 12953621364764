import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"
import { StaticImage } from "gatsby-plugin-image"

const error = () => {

  const children =
    <StaticImage
    className="!absolute top-0 bottom-0 left-0 right-0 z-0 opacity-80"
    imgClassName="absolute top-0 bottom-0 left-0 right-0 z-0 "
    src="../../static/404.jpg"
    alt="Servizi" 
    placeholder="blurred"/>

  return (
    <>
      <Seo title="Error" />
      <Layout>

        <SimpleBanner title="Pagina non trovata" back={children} />

        <section className="relative py-20 md:py-40 px-9 md:px-20">
          <div className="mx-auto max-w-7xl">
            <h1 className={`inline-block border-b-2 border-zinc-700 text-white
            font-bold text-3xl md:text-4xl xl:text-4xl pb-1`}>
              Ops
              <span className="text-yellow-500 text-3xl md:text-4xl">.</span>
            </h1>
          
            <h3 className="mt-10 mb-14 md:max-w-7xl text-base md:text-lg">
              Sembra che la pagina ricercata non sia disponibile.
            </h3>
          
            <p className="mt-10 mb-14 md:max-w-7xl text-base md:text-lg">
              Continua a navigare selezionando una pagina dai menù in alto o in basso.
            </p>

            <Button text="Torna alla Home" to="/" as={Link} />
          </div>
        </section>
      </Layout>
    </>
  )
}

export default error
